import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { SsnUtils } from '../../Common/util/ssn-utils';
import { ITableData } from '../../models/TableData';

export class OTSRosterTableData implements ITableData {
  entityId: number;
  isArchived: boolean;
  firstName: string | null;
  lastName: string | null;
  ssn: string | null;
  email: string | null;
  posNum: string | null;

  constructor(candidate: BaseCandidate) {
    this.entityId = candidate.id;
    this.isArchived = candidate.isArchived;
    this.firstName = candidate.identificationInformationFirstname;
    this.lastName = candidate.identificationInformationLastname;
    this.ssn = SsnUtils.ConvertToReadableSSN(
      candidate.identificationInformationSocialsecuritynumber
    );
    this.email = candidate.contactInformationEmail;
    this.posNum = candidate.gainsPositionNumber;
  }
}
