import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ITableConfiguration } from '@/models/Table/Table';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import { OTSRosterTableData } from './OTSRosterTableData';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import Routes from '@/router/Routes';

export const OTSRosterTableConfigBuilder: TableConfigBuilder<
  OTSRosterTableData,
  BaseCandidate,
  never,
  SearchCandidateDto
> = (): ITableConfiguration<OTSRosterTableData, BaseCandidate, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Last Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (rowData) => {
            return {
              displayString: rowData.lastName,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'identificationInformationLastname'
      },
      {
        columnTitle: 'First Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (rowData) => {
            return {
              displayString: rowData.firstName,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'identificationInformationFirstname'
      },
      {
        columnTitle: 'Social Security Number',
        render: 'ssn'
      },
      {
        columnTitle: 'Email',
        render: 'email',
        sortKey: 'contactInformationEmail'
      },
      {
        columnTitle: 'POS Num',
        render: 'posNum',
        sortKey: 'gainsPositionNumber'
      }
    ]
  };
};
