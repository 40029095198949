
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { OTSRosterDataProvider } from '@/components/Table/OfficerTrainingSchoolClass/Roster/OTSRosterDataProvider';
import { OTSRosterTableData } from '@/components/Table/OfficerTrainingSchoolClass/Roster/OTSRosterTableData';
import { OTSRosterTableConfigBuilder } from '@/components/Table/OfficerTrainingSchoolClass/Roster/OTSRosterTableConfigBuilder';
import FetchMixin from '@/mixins/FetchMixin';
import Routes from '@/router/Routes';
import { SearchOfficerTrainingSchoolClassDto } from '@/models/Dtos/searchOfficerTrainingSchoolClassDto';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { formatDate, formatTime } from '@/services/formatService';
@Component<OtsRosterDetailsPage>({
  components: {
    ButtonComponent,
    IconComponent,
    PageLayout,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent
  }
})
export default class OtsRosterDetailsPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;

  fromReports = 'false';

  setLocation(): void {
    if (this.$route.params.fromReports)
      this.fromReports = this.$route.params.fromReports;
  }

  get pathing(): Routes {
    return this.fromReports === 'true'
      ? Routes.REPORTS_DASHBOARD
      : Routes.STUDENT_DASHBOARD;
  }

  get naming(): string {
    return this.fromReports === 'true' ? 'Reports /' : '340th Dashboard /';
  }

  @Prop()
  readonly otsId!: number;

  startDate: string | null = '';

  graduationDate: string | null = '';

  className: string | null = '';

  searchService: TableSearchService<
    OTSRosterTableData,
    BaseCandidate,
    never,
    SearchCandidateDto
  > | null = null;

  async created(): Promise<void> {
    this.setLocation();
    this.searchService = new TableSearchService<
      OTSRosterTableData,
      BaseCandidate,
      never,
      SearchCandidateDto
    >(
      this.$store,
      new OTSRosterDataProvider(this.$store),
      {
        otsId: this.otsId
      },
      { defaultSortProperty: 'identificationInformationLastname' },
      OTSRosterTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
    await this.queryDatesAndName();
  }

  async queryDatesAndName(): Promise<void> {
    const query: SearchOfficerTrainingSchoolClassDto = {
      otsId: this.otsId
    };
    const otsClass = await this.getOne({
      path: 'officerTrainingSchoolClass',
      query,
      model: OfficerTrainingSchoolClass
    });
    this.className = otsClass.classNumber;
    if (otsClass.graduationDate) {
      const gradDate = formatDate(otsClass.graduationDate);
      const gradTime = formatTime(otsClass.graduationDate);
      this.graduationDate = `${gradDate} at ${gradTime}`;
    }
    if (otsClass.startDate) {
      const startDate = formatDate(otsClass.startDate);
      const startTime = formatTime(otsClass.startDate);
      this.startDate = `${startDate} at ${startTime}`;
    }
  }
}
